/**
 * @file Component that implements the Login with Shop Default Authorize flow (with no side-effects).
 */
import {checkEligibleForCompactLayout} from 'common/utils/isCompactLayout';
import {constraintWidthInViewport} from 'common/utils/constraintWidthInViewport';
import {ShopifyPayModalState} from 'common/analytics';
import {ShopUserNotMatchedEvent} from 'types/loginButton';

import {defineCustomElement} from '../../common/init';
import InputListener from '../../common/InputListener';
import {logError} from '../../common/logging';
import Bugsnag from '../../common/bugsnag';
import {parseIntOrUndefined} from '../../common/utils/coerce';
import {IFrameEventSource} from '../../common/MessageEventSource';
import MessageListener from '../../common/MessageListener';
import {
  AbortSignalReceivedError,
  getAnalyticsTraceId,
  isValidEmail,
  exchangeLoginCookie,
  debounce,
  ShopHubTopic,
  updateIframeSrc,
  removeOnePasswordPrompt,
  PositionVariant,
  unzoomIos,
} from '../../common/utils';
import {
  PAY_AUTH_DOMAIN,
  PAY_AUTH_DOMAIN_ALT,
  validateStorefrontOrigin,
} from '../../common/utils/urls';
import {I18n} from '../../common/translator/i18n';
import ConnectedWebComponent from '../../common/ConnectedWebComponent';
import {PayMessageSender} from '../../common/MessageSender';
import {
  AuthorizeModalTextActionType,
  LoginButtonMessageEventData,
  LoginButtonMessageEventData as MessageEventData,
  AuthorizeLoadedEvent,
  CustomFlowSideEffectEvent,
  OAuthParams,
  SdkErrorCode,
  ShopActionType,
  ShopUserMatchedEvent,
  VerificationStepChangedEvent,
  LoginButtonVersion as Version,
  AuthenticationLevel,
  OAuthParamsV1,
  LoginButtonUxMode as UxMode,
} from '../../types';
import {
  ATTRIBUTE_ANCHOR_SELECTOR,
  ATTRIBUTE_AUTO_OPEN,
  ATTRIBUTE_CLIENT_ID,
  ATTRIBUTE_DISABLE_SIGN_UP,
  ATTRIBUTE_EMAIL_VERIFICATION_REQUIRED,
  ATTRIBUTE_HIDE_BUTTON,
  ATTRIBUTE_REDIRECT_TYPE,
  ATTRIBUTE_REDIRECT_URI,
  ATTRIBUTE_UX_MODE,
  ATTRIBUTE_STOREFRONT_ORIGIN,
  ATTRIBUTE_VERSION,
  ATTRIBUTE_ANALYTICS_CONTEXT,
  ATTRIBUTE_ANALYTICS_TRACE_ID,
  ATTRIBUTE_RESPONSE_TYPE,
  ATTRIBUTE_RESPONSE_MODE,
  ATTRIBUTE_CODE_CHALLENGE,
  ATTRIBUTE_CODE_CHALLENGE_METHOD,
  ATTRIBUTE_STATE,
  ATTRIBUTE_SCOPE,
  ATTRIBUTE_AVOID_PAY_ALT_DOMAIN,
  ATTRIBUTE_FLOW,
  ATTRIBUTE_KEEP_MODAL_OPEN,
  ATTRIBUTE_FLOW_VERSION,
  ERRORS,
  LOAD_TIMEOUT_MS,
  EMAIL_LISTENER_DEBOUNCE_MS,
  ATTRIBUTE_EMAIL,
  ATTRIBUTE_MODAL_TITLE,
  ATTRIBUTE_MODAL_DESCRIPTION,
  ATTRIBUTE_MODAL_LOGO_SRC,
  ATTRIBUTE_API_KEY,
  ATTRIBUTE_POP_UP_NAME,
  ATTRIBUTE_POP_UP_FEATURES,
  ATTRIBUTE_MODAL_BRAND,
  ATTRIBUTE_CONSENT_CHALLENGE,
  ATTRIBUTE_CHECKOUT_REDIRECT_URL,
  ATTRIBUTE_CHECKOUT_VERSION,
  ATTRIBUTE_CHECKOUT_TOKEN,
  ATTRIBUTE_TRANSACTION_PARAMS,
  ATTRIBUTE_SHOP_ID,
  ATTRIBUTE_FIRST_NAME,
  ATTRIBUTE_LAST_NAME,
  ATTRIBUTE_REQUIRE_VERIFICATION,
  ATTRIBUTE_COMPACT,
  ATTRIBUTE_POSITION_VARIANT,
  ATTRIBUTE_AVOID_SDK_SESSION,
  ATTRIBUTE_SHOP_PERMANENT_DOMAIN,
  ATTRIBUTE_SOURCE,
} from '../../constants/loginButton';
import {DefaultComponentAnalyticsContext} from '../../constants/loginDefault';
import {
  recordOpentel,
  TelemetryMetricId,
} from '../../dynamicImports/opentelemetry';

import {DefaultComponentMonorailTracker} from './analytics';
import {buildAuthorizeUrl} from './authorize';
import LoginDefaultView from './view/LoginDefaultView';
import {shouldHidePayCopy} from './utils/authorizeModalTexts';
import {validateModalCustomizations} from './utils/modalCustomizations';

const SILENT_SDK_ERROR_CODES: SdkErrorCode[] = [SdkErrorCode.CaptchaChallenge];

export default class ShopLoginDefault extends ConnectedWebComponent {
  _rootElement: ShadowRoot;
  #analyticsTraceId = getAnalyticsTraceId();
  #clientId = '';
  #version: Version = '2';
  #storefrontOrigin = window.location.origin;
  #monorailTracker = new DefaultComponentMonorailTracker({
    elementName: 'shop-login-default',
    analyticsTraceId: this.#analyticsTraceId,
  });

  #redirectType: OAuthParams['redirectType'];
  #redirectUri: string | undefined;
  #uxMode: UxMode | undefined;
  #popUpName: string | undefined;
  #popUpFeatures: string | undefined;

  #i18n: I18n | null = null;

  private _view: LoginDefaultView | undefined;
  private _emailVerificationRequired = false;
  private _disableSignUp = false;
  // _autoOpen is syncronized with the html attribute `auto-open`.
  private _autoOpen = false;
  // _autoOpened is set to true whenever the modal is opened automatically.
  private _autoOpened = false;
  private _analyticsContext = DefaultComponentAnalyticsContext.Default;
  private _apiKey: string | undefined;

  private _avoidPayAltDomain = false;
  private _avoidSdkSession = false;
  private _flow = ShopActionType.Default;
  private _flowVersion = 'unspecified';

  private _inputListener: InputListener | undefined;

  private _iframe: HTMLIFrameElement | undefined;
  private _iframeListener:
    | MessageListener<LoginButtonMessageEventData>
    | undefined;

  private _iframeSrcTimeout: ReturnType<typeof setTimeout> | undefined;

  private _hideButton = false;
  private _anchorSelector = '';
  private _iframeMessenger?: PayMessageSender;
  private _iframeLoadTimeout: ReturnType<typeof setTimeout> | undefined;
  private _updateEmailAbortController: AbortController | undefined;
  private _debouncedUpdateUserInfo: (userInfo: {
    email: string;
    firstName?: string;
    lastName?: string;
  }) => void;

  private _compactRequested = false;
  private _isCompactLayout = false;
  private _hidePayCopy = false;

  private _responseType: string | undefined;
  private _responseMode: string | undefined;
  private _codeChallenge: string | undefined;
  private _codeChallengeMethod: string | undefined;
  private _state: string | undefined;
  private _scope: string | undefined;
  private _email = '';
  private _checkoutRedirectUrl: string | undefined;
  private _checkoutVersion: string | undefined;
  private _checkoutToken: string | undefined;
  private _transactionParams: string | undefined;

  private _payLoaded: Promise<{userFound: boolean}>;
  // A reference to the internal promise resolver of `_payLoaded`.
  private _payLoadedResolve: (value: {userFound: boolean}) => void;

  private _authorizeModalOpened = false;
  private _keepModalOpen = false;
  private _consentChallenge: boolean | undefined;
  private _shopId: string | undefined;
  private _shopPermanentDomain: string | undefined;
  private _firstName: string | undefined;
  private _lastName: string | undefined;
  private _requireVerification = false;
  private _shouldListenToResizeMessage = true;
  private _source: string | undefined;

  static get observedAttributes(): string[] {
    return [
      ATTRIBUTE_ANCHOR_SELECTOR,
      ATTRIBUTE_CLIENT_ID,
      ATTRIBUTE_VERSION,
      ATTRIBUTE_STOREFRONT_ORIGIN,
      ATTRIBUTE_EMAIL_VERIFICATION_REQUIRED,
      ATTRIBUTE_HIDE_BUTTON,
      ATTRIBUTE_DISABLE_SIGN_UP,
      ATTRIBUTE_AUTO_OPEN,
      ATTRIBUTE_REDIRECT_TYPE,
      ATTRIBUTE_REDIRECT_URI,
      ATTRIBUTE_UX_MODE,
      ATTRIBUTE_ANALYTICS_CONTEXT,
      ATTRIBUTE_ANALYTICS_TRACE_ID,
      ATTRIBUTE_COMPACT,
      ATTRIBUTE_POSITION_VARIANT,
      ATTRIBUTE_RESPONSE_TYPE,
      ATTRIBUTE_RESPONSE_MODE,
      ATTRIBUTE_CODE_CHALLENGE,
      ATTRIBUTE_CODE_CHALLENGE_METHOD,
      ATTRIBUTE_STATE,
      ATTRIBUTE_SCOPE,
      ATTRIBUTE_AVOID_PAY_ALT_DOMAIN,
      ATTRIBUTE_AVOID_SDK_SESSION,
      ATTRIBUTE_FLOW,
      ATTRIBUTE_FLOW_VERSION,
      ATTRIBUTE_EMAIL,
      ATTRIBUTE_MODAL_TITLE,
      ATTRIBUTE_MODAL_DESCRIPTION,
      ATTRIBUTE_MODAL_LOGO_SRC,
      ATTRIBUTE_API_KEY,
      ATTRIBUTE_POP_UP_NAME,
      ATTRIBUTE_POP_UP_FEATURES,
      ATTRIBUTE_MODAL_BRAND,
      ATTRIBUTE_CONSENT_CHALLENGE,
      ATTRIBUTE_CHECKOUT_REDIRECT_URL,
      ATTRIBUTE_CHECKOUT_VERSION,
      ATTRIBUTE_CHECKOUT_TOKEN,
      ATTRIBUTE_TRANSACTION_PARAMS,
      ATTRIBUTE_SHOP_ID,
      ATTRIBUTE_SHOP_PERMANENT_DOMAIN,
      ATTRIBUTE_FIRST_NAME,
      ATTRIBUTE_LAST_NAME,
      ATTRIBUTE_REQUIRE_VERIFICATION,
      ATTRIBUTE_SOURCE,
    ];
  }

  constructor() {
    super();

    this._rootElement = this.attachShadow({mode: 'open'});

    this._analyticsContext =
      (this.getAttribute(
        ATTRIBUTE_ANALYTICS_CONTEXT,
      ) as DefaultComponentAnalyticsContext) ||
      DefaultComponentAnalyticsContext.Default;

    this._view = new LoginDefaultView(this._rootElement, {
      onOpen: this.#onViewOpen,
      onClose: this.#onViewClose,
    });

    this._compactRequested = this.getBooleanAttribute(ATTRIBUTE_COMPACT);
    this.#updateIfCompactEligible(true);

    this._debouncedUpdateUserInfo = debounce(({email, firstName, lastName}) => {
      this._updateUserInfo({
        email,
        firstName,
        lastName,
      });

      if (isValidEmail(email)) {
        this.#monorailTracker.trackEmailEnteredAction();
      }
    }, EMAIL_LISTENER_DEBOUNCE_MS);

    this._payLoadedResolve = () => {};
    this._payLoaded = new Promise((resolve) => {
      this._payLoadedResolve = resolve;
    });
  }

  #handleUserIdentityChange = () => {
    this._updateSrc(true);
  };

  #onViewClose = () => {
    this.dispatchCustomEvent('modalclosed');
    this._iframeMessenger?.postMessage({type: 'sheetmodalclosed'});
    // Remove the 1password custom element from the DOM after the sheet modal is closed.
    removeOnePasswordPrompt();
  };

  #onViewOpen = () => {
    this.dispatchCustomEvent('modalopened');
    this._iframeMessenger?.postMessage({type: 'sheetmodalopened'});
  };

  /**
   * updates the internal flag which tells the component:
   * - whether it should be compact
   * - whether the Pay iframe should render the content
   * it also reloads the view, if needed
   * @param {boolean} forceInit initialize without checking equal values
   * @returns {boolean} has the view been updated or not
   */
  #updateIfCompactEligible = (forceInit?: boolean): boolean => {
    const newIsCompactLayout =
      this._compactRequested &&
      checkEligibleForCompactLayout(this._analyticsContext);
    this._hidePayCopy = newIsCompactLayout
      ? false
      : shouldHidePayCopy(this._analyticsContext);

    // stop here if nothing is different based on new calculations
    if (forceInit || this._isCompactLayout !== newIsCompactLayout) {
      this._isCompactLayout = newIsCompactLayout;
      this._view?.setCompact(this._isCompactLayout);
      this._view?.init();

      return true;
    }

    return false;
  };

  attributeChangedCallback(
    name: string,
    _oldValue: string,
    newValue: string | null,
  ): void {
    // For boolean attributes only check if the attribute is passed, ignoring its value
    const attributePassed = Boolean(newValue !== null);

    switch (name) {
      case ATTRIBUTE_VERSION:
        this.#version = newValue as Version;
        this._updateSrc();
        break;
      case ATTRIBUTE_CLIENT_ID:
        this.#clientId = newValue || '';
        this._updateSrc();
        break;
      case ATTRIBUTE_RESPONSE_TYPE:
        this._responseType = newValue || '';
        this._updateSrc();
        break;
      case ATTRIBUTE_RESPONSE_MODE:
        this._responseMode = newValue || '';
        this._updateSrc();
        break;
      case ATTRIBUTE_CODE_CHALLENGE:
        this._codeChallenge = newValue || '';
        this._updateSrc();
        break;
      case ATTRIBUTE_CODE_CHALLENGE_METHOD:
        this._codeChallengeMethod = newValue || '';
        this._updateSrc();
        break;
      case ATTRIBUTE_STATE:
        this._state = newValue || '';
        this._updateSrc();
        break;
      case ATTRIBUTE_SCOPE:
        this._scope = newValue || '';
        this._updateSrc();
        break;
      case ATTRIBUTE_STOREFRONT_ORIGIN:
        this.#storefrontOrigin = newValue || window.location.origin;
        validateStorefrontOrigin(this.#storefrontOrigin);
        break;
      case ATTRIBUTE_EMAIL_VERIFICATION_REQUIRED:
        this._emailVerificationRequired = attributePassed;
        this._updateSrc();
        break;
      case ATTRIBUTE_HIDE_BUTTON:
        this._hideButton = attributePassed;
        this._view?.setContinueButtonVisible(attributePassed);
        this._updateSrc();
        break;
      case ATTRIBUTE_AVOID_PAY_ALT_DOMAIN:
        this._avoidPayAltDomain = attributePassed;
        this._updateSrc();
        break;
      case ATTRIBUTE_AVOID_SDK_SESSION:
        this._avoidSdkSession = attributePassed;
        this._updateSrc();
        break;
      case ATTRIBUTE_FLOW:
        this._flow = (newValue as ShopActionType) || ShopActionType.Default;
        this._view?.setFlow(this._flow);
        this._updateSrc();
        break;
      case ATTRIBUTE_FLOW_VERSION:
        this._flowVersion = newValue || 'unspecified';
        this._view?.setFlowVersion(this._flowVersion);
        this._updateMonorailTracker();
        this._updateSrc();
        break;
      case ATTRIBUTE_DISABLE_SIGN_UP:
        this._disableSignUp = attributePassed;
        this._updateSrc();
        break;
      case ATTRIBUTE_AUTO_OPEN:
        this._autoOpen = attributePassed;
        break;
      case ATTRIBUTE_REDIRECT_TYPE:
        this.#redirectType =
          newValue === 'pop_up' || newValue === 'iframe'
            ? newValue
            : 'top_frame';
        this._updateSrc();
        break;
      case ATTRIBUTE_REDIRECT_URI:
        this.#redirectUri = newValue || undefined;
        this._updateSrc();
        break;
      case ATTRIBUTE_UX_MODE:
        this.#uxMode = (newValue as UxMode) || undefined;
        this._view?.setUxMode(this.#uxMode);
        this._updateSrc();
        break;
      case ATTRIBUTE_ANALYTICS_CONTEXT:
        this._analyticsContext =
          (newValue as DefaultComponentAnalyticsContext) ||
          DefaultComponentAnalyticsContext.Default;
        this._view?.setAnalyticsContext(this._analyticsContext);
        this.#updateIfCompactEligible();
        this._updateMonorailTracker();
        this._updateSrc();
        break;
      case ATTRIBUTE_ANALYTICS_TRACE_ID:
        this.#analyticsTraceId = newValue || getAnalyticsTraceId();
        this._updateMonorailTracker();
        this._updateSrc();
        break;
      case ATTRIBUTE_COMPACT:
        this._compactRequested = attributePassed;
        if (this.#updateIfCompactEligible()) {
          this._updateSrc(true);
        }
        break;
      case ATTRIBUTE_POSITION_VARIANT:
        this._view?.setModalPositionVariant(
          (newValue || PositionVariant.Default) as PositionVariant,
        );
        break;
      case ATTRIBUTE_EMAIL:
        this._initEmail(newValue || '');
        break;
      case ATTRIBUTE_ANCHOR_SELECTOR:
        this._anchorSelector = newValue || '';
        this._view?.setAnchorSelector(newValue || '');
        break;
      case ATTRIBUTE_MODAL_TITLE:
        this._view?.setCustomizedModalContent({
          modalTitle: newValue || undefined,
        });
        this._updateSrc();
        break;
      case ATTRIBUTE_MODAL_DESCRIPTION:
        this._view?.setCustomizedModalContent({
          modalDescription: newValue || undefined,
        });
        this._updateSrc();
        break;
      case ATTRIBUTE_MODAL_LOGO_SRC:
        this._view?.setCustomizedModalContent({
          modalLogo: newValue || undefined,
        });
        this._updateSrc();
        break;
      case ATTRIBUTE_API_KEY:
        this._apiKey = newValue || undefined;
        this._updateSrc();
        break;
      case ATTRIBUTE_POP_UP_NAME:
        this.#popUpName = newValue || undefined;
        this._updateSrc();
        break;
      case ATTRIBUTE_POP_UP_FEATURES:
        this.#popUpFeatures = newValue || undefined;
        this._updateSrc();
        break;
      case ATTRIBUTE_MODAL_BRAND:
        this._view?.setBrand(newValue || undefined);
        break;
      case ATTRIBUTE_CONSENT_CHALLENGE:
        this._consentChallenge = attributePassed;
        this._updateSrc();
        break;
      case ATTRIBUTE_CHECKOUT_REDIRECT_URL:
        this._checkoutRedirectUrl = newValue || undefined;
        this._updateSrc();
        break;
      case ATTRIBUTE_CHECKOUT_VERSION:
        this._checkoutVersion = newValue || undefined;
        this._updateSrc();
        this._updateMonorailTracker();
        break;
      case ATTRIBUTE_CHECKOUT_TOKEN:
        this._checkoutToken = newValue || undefined;
        this._updateSrc();
        this._updateMonorailTracker();
        break;
      case ATTRIBUTE_TRANSACTION_PARAMS:
        this._transactionParams = newValue || undefined;
        this._updateSrc();
        break;
      case ATTRIBUTE_SHOP_ID:
        this._shopId = newValue || undefined;
        this._updateSrc();
        this._updateMonorailTracker();
        break;
      case ATTRIBUTE_SHOP_PERMANENT_DOMAIN:
        this._shopPermanentDomain = newValue || undefined;
        this._updateMonorailTracker();
        break;
      case ATTRIBUTE_FIRST_NAME:
        this._firstName = newValue || undefined;
        break;
      case ATTRIBUTE_LAST_NAME:
        this._lastName = newValue || undefined;
        break;
      case ATTRIBUTE_REQUIRE_VERIFICATION:
        this._requireVerification = attributePassed;
        this._updateSrc();
        break;
      case ATTRIBUTE_SOURCE:
        this._source = newValue || 'unspecified';
        break;
    }
  }

  async connectedCallback(): Promise<void> {
    this.subscribeToHub(
      ShopHubTopic.UserStatusIdentity,
      this.#handleUserIdentityChange,
    );

    this._keepModalOpen = this.getBooleanAttribute(ATTRIBUTE_KEEP_MODAL_OPEN);
    this._hideButton = this.getBooleanAttribute(ATTRIBUTE_HIDE_BUTTON);
    this._apiKey = this.getAttribute(ATTRIBUTE_API_KEY) || undefined;
    this._source = this.getAttribute(ATTRIBUTE_SOURCE) || 'unspecified';

    try {
      validateModalCustomizations(
        this._view!.getCustomizedModalContent(),
        this._apiKey,
      );

      await this.#initTranslations();
      await this._initElements();
      validateStorefrontOrigin(this.#storefrontOrigin);

      this.#monorailTracker.trackFeatureInitialization({
        apiKey: this._apiKey,
        source: this._source,
      });
    } catch (error) {
      if (error instanceof Error) {
        logError(`Invalid config. ${error.message}`);
        this._handleError('invalid_config', SdkErrorCode.ApiUnavailable);
      }
    }
  }

  async #initTranslations() {
    try {
      // BUILD_LOCALE is used for generating localized bundles.
      // See ./scripts/i18n-dynamic-import-replacer-rollup.mjs for more info.
      // eslint-disable-next-line no-process-env
      const locale = process.env.BUILD_LOCALE || I18n.getDefaultLanguage();
      const dictionary = await import(`./translations/${locale}.json`);
      this.#i18n = new I18n({[locale]: dictionary});
      this._view?.setTranslations(this.#i18n);
    } catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
    }
    return null;
  }

  async _initElements() {
    if (!this._view) return;

    this._view.setModalAnalyticsTraceId(this.#analyticsTraceId);
    this._view.setMonorailTracker(this.#monorailTracker);
    this._view.setAnchorSelector(this._anchorSelector);
    this._view.setContinueButtonVisible(!this._hideButton);

    this._iframe = this._view.getIframe();
    this._updateSrc();

    const eventDestination: Window | undefined =
      this.ownerDocument?.defaultView || undefined;

    this._iframeListener = new MessageListener<LoginButtonMessageEventData>(
      new IFrameEventSource(this._iframe!),
      [PAY_AUTH_DOMAIN, PAY_AUTH_DOMAIN_ALT, this.#storefrontOrigin],
      this.#handlePostMessage.bind(this),
      eventDestination,
    );

    this._iframeMessenger = new PayMessageSender(this._iframe!);

    const {userFound} = await this._iframeListener.waitForMessage('loaded');
    this._payLoadedResolve({userFound});

    this.dispatchCustomEvent('iframeloaded');
    this.#monorailTracker.trackShopPayModalStateChange({
      currentState: ShopifyPayModalState.Loaded,
    });

    this.#clearLoadTimeout();

    if (userFound && this._autoOpen && !this._autoOpened) {
      this._updateEmailAbortController?.abort();
      this._autoOpened = true;
      await this._view.openAuthorizeModal();
    }
  }

  _initEmail(email: string) {
    this._email = email;
    if (isValidEmail(this._email)) {
      this._debouncedUpdateUserInfo({
        email: this._email,
        firstName: this._firstName,
        lastName: this._lastName,
      });
    } else {
      // // Send empty email to Pay to trigger a prop change if the same email is re-submitted
      this._debouncedUpdateUserInfo({
        email: '',
      });
    }
  }

  disconnectedCallback(): void {
    this.unsubscribeAllFromHub();
    this._iframeListener?.destroy();
    this._view?.destroy();
    this.stopListeningToInput();
  }

  /**
   * Used by Shop Pay Commerce Component to prevent the iframe from resizing
   * when the iframe source is set to Shop Pay checkout.
   * @param {boolean} value whether the resize iframe message should be listened to.
   */
  setShouldListenToResizeMessage(value: boolean) {
    this._shouldListenToResizeMessage = value;
  }

  /**
   * Sends the given email to Pay if provided. Opens the modal regardless of whether the email
   * matched a user or not or if it was provided at all.
   * @param {string} [email] Optional Email address to initialize the modal with.
   */
  async requestShow(email?: string): Promise<void> {
    await this._payLoaded;

    if (this._authorizeModalOpened) {
      Bugsnag.notify(
        new Error('requestShow called when the modal is not closed'),
      );
      return;
    }

    if (email) {
      this._updateUserInfo({
        email,
        firstName: this._firstName,
        lastName: this._lastName,
      });
    }

    await this._payLoaded;
    await this._view?.openAuthorizeModal();
  }

  /**
   * Listens for changes to an email input field. If the input contains an email
   * that matches a Pay user, the modal is opened. If email-verification-required
   * is set, the modal will only open for email verified Pay users.
   *
   * Ensure disable-sign-up is set when calling this. Otherwise, if an email does
   * not match a Pay user the sign up form will open in the background but the modal
   * will not be shown.
   * @param {object} inputElement Email input element to listen to.
   * @deprecated Use the email attribute instead
   */
  listenToInput(inputElement: HTMLInputElement): void {
    this.stopListeningToInput();

    const handleNewValue = debounce((value: string) => {
      if (isValidEmail(value)) {
        this._updateUserInfo({
          email: value,
        });
        this.#monorailTracker.trackEmailEnteredAction();
      } else {
        // Send empty email to Pay to trigger a prop change if the same email is re-submitted
        this._updateUserInfo({
          email: '',
        });
      }
    }, EMAIL_LISTENER_DEBOUNCE_MS);

    handleNewValue(inputElement.value);
    this._inputListener = new InputListener(inputElement, handleNewValue);
  }

  getIframe() {
    return this._view?.getIframe();
  }

  /**
   * @returns {Promise} A promise that resolves when the iframe is loaded
   */
  ensureIframeIsLoaded(): Promise<void> {
    return this._payLoaded.then(() => undefined);
  }

  /**
   * Stops listening to input changes. Call `listenToInput` again to start listening again.
   */
  stopListeningToInput() {
    this._inputListener?.destroy();
  }

  async _updateUserInfo({
    email,
    firstName = '',
    lastName = '',
  }: {
    email: string;
    firstName?: string;
    lastName?: string;
  }): Promise<void> {
    /**
     * Ensures that we do not submit a new email to the iframe if the sheet modal is already visible to the user
     */
    if (this._authorizeModalOpened) {
      return;
    }

    if (
      this._updateEmailAbortController &&
      !this._updateEmailAbortController?.signal.aborted
    ) {
      this._updateEmailAbortController.abort();
    }

    this._updateEmailAbortController = new AbortController();

    try {
      const {userFound} = await this._payLoaded;

      if (userFound && this._autoOpen && !this._autoOpened) {
        return;
      }

      // Submit the name at the same time as the email, since an email change represents
      // a change in user and also causes the modal to appear.
      this._iframeMessenger!.postMessage({
        type: 'namesubmitted',
        firstName,
        lastName,
      });

      this._iframeMessenger!.postMessage({
        type: 'emailsubmitted',
        email,
        hideChange: email.length > 0,
      });

      const shopUserMatchedPromise = this._iframeListener!.waitForMessage(
        'shop_user_matched',
        this._updateEmailAbortController.signal,
      );
      const captchaChallengePromise = new Promise((resolve, reject) => {
        const waitForCaptcha = async () => {
          try {
            const {code} = await this._iframeListener!.waitForMessage(
              'error',
              this._updateEmailAbortController!.signal,
            );

            if (code === SdkErrorCode.CaptchaChallenge) {
              resolve(undefined);
            } else {
              waitForCaptcha();
            }
          } catch (err) {
            reject(err);
          }
        };

        waitForCaptcha();
      });

      await Promise.race([shopUserMatchedPromise, captchaChallengePromise]);

      await this._view?.openAuthorizeModal();

      // Ensures `waitForMessage` calls are cancelled after the modal is opened
      this._updateEmailAbortController.abort();
    } catch (error) {
      if (error instanceof AbortSignalReceivedError) {
        return;
      }
      if (error instanceof Error) {
        Bugsnag.notify(
          new Error(
            `Error updating user info: ${error.name} - ${error.message}`,
          ),
        );
      }
    }
  }

  _updateSrc(forced?: boolean) {
    const iframe = this._view?.getIframe();

    if (!iframe && this._view?.getUxMode() !== 'redirect') return;

    const authorizeUrl = this._buildAuthorizeUrl();
    this._view?.setAuthorizeUrl(authorizeUrl);

    if (!iframe || !authorizeUrl) return;

    this._updateListeners(iframe);

    if (this._iframeSrcTimeout) {
      clearTimeout(this._iframeSrcTimeout);
    }

    // Wait for the browser to make all `attributeChangedCallback` calls before updating
    // the iframe src to avoid multiple cancelled requests.
    this._iframeSrcTimeout = setTimeout(() => {
      this.#initLoadTimeout();
      updateIframeSrc(iframe, authorizeUrl, forced);
      Bugsnag.leaveBreadcrumb('Iframe url updated', {authorizeUrl}, 'state');
    }, 0);
  }

  _buildAuthorizeUrl(): string {
    const oauthParams: OAuthParams | OAuthParamsV1 = {
      clientId: this.#clientId,
      responseType: this._responseType,
      responseMode: this._responseMode,
      redirectType: this.#redirectType,
      redirectUri: this.#redirectUri,
      codeChallenge: this._codeChallenge,
      codeChallengeMethod: this._codeChallengeMethod,
      state: this._state,
      scope: this._scope,
    };
    const modalCustomized = this._view?.isModalCustomized();

    return buildAuthorizeUrl({
      version: this.#version,
      analyticsTraceId: this.#analyticsTraceId,
      analyticsContext: this._analyticsContext,
      isCompactLayout: this._isCompactLayout,
      flow: this._flow,
      flowVersion: this._flowVersion,
      emailVerificationRequired: this._emailVerificationRequired,
      signUpEnabled: !this._disableSignUp,
      oauthParams,
      avoidPayAltDomain: this._avoidPayAltDomain,
      avoidSdkSession: this._avoidSdkSession,
      hideCopy: this._hidePayCopy,
      ...(modalCustomized && {modalCustomized}),
      apiKey: this._apiKey,
      popupWindowParams: {
        popUpName: this.#popUpName,
        popUpFeatures: this.#popUpFeatures,
      },
      consentChallenge: this._consentChallenge,
      checkoutRedirectUrl: this._checkoutRedirectUrl,
      checkoutVersion: this._checkoutVersion,
      checkoutToken: this._checkoutToken,
      transactionParams: this._transactionParams,
      shopId: this._shopId,
      requireVerification: this._requireVerification,
      uxMode: this.#uxMode,
    });
  }

  /**
   * When the template changes and destroys our iframe reference we need to reinitalize our listener with a reference
   * to the new iframe element. This is done to ensure that our event listeners continue to operate as expected
   * and do not fail the event source validation.
   * @param {object} iframe The new iframe element to use for our event listener.
   */
  _updateListeners(iframe: HTMLIFrameElement) {
    if (this._iframeListener) {
      this._iframeListener.eventSource = new IFrameEventSource(iframe);
    }

    if (this._iframeMessenger) {
      this._iframeMessenger.eventDestination = iframe;
    }
  }

  _updateMonorailTracker() {
    this.#monorailTracker = new DefaultComponentMonorailTracker({
      elementName: 'shop-login-default',
      analyticsTraceId: this.#analyticsTraceId,
      analyticsContext: this._analyticsContext,
      flowVersion: this._flowVersion,
      shopId: parseIntOrUndefined(this._shopId),
      shopPermanentDomain: this._shopPermanentDomain,
      checkoutVersion: this._checkoutVersion,
      checkoutToken: this._checkoutToken,
    });
    this._view?.setMonorailTracker(this.#monorailTracker);
  }

  #initLoadTimeout() {
    this.#clearLoadTimeout();
    this._iframeLoadTimeout = setTimeout(() => {
      const error = ERRORS.temporarilyUnavailable;
      this.dispatchCustomEvent('error', {
        message: error.message,
        code: error.code,
      });
      // eslint-disable-next-line no-warning-comments
      // TODO: replace this bugsnag notify with a Observe-able event
      // Bugsnag.notify(
      //   new PayTimeoutError(`Pay failed to load within ${LOAD_TIMEOUT_MS}ms.`),
      //   {component: this.#component, src: this.iframe?.getAttribute('src')},
      // );
      this.#clearLoadTimeout();
    }, LOAD_TIMEOUT_MS);
  }

  #clearLoadTimeout() {
    if (!this._iframeLoadTimeout) return;
    clearTimeout(this._iframeLoadTimeout);
    this._iframeLoadTimeout = undefined;
  }

  async _handleCompleted(
    loggedIn: boolean,
    email?: string,
    customerAccessToken?: string,
    customerAccessTokenExpiresAt?: string,
    shouldFinalizeLogin?: boolean,
    shopPayInstallmentsOnboarded?: boolean,
    givenNameFirstInitial?: string,
    avatar?: string,
  ) {
    if (loggedIn) {
      if (shouldFinalizeLogin) {
        await exchangeLoginCookie(this.#storefrontOrigin, Bugsnag.notify);
        this.publishToHub(ShopHubTopic.UserSessionCreate, {
          email: givenNameFirstInitial || email,
          initial: givenNameFirstInitial || email?.[0] || '',
          avatar,
        });
      }
    }

    if (!this._keepModalOpen) {
      await this._view?.closeAuthorizeModal();
    }

    this._iframeListener?.destroy();
    this.stopListeningToInput();

    this.dispatchCustomEvent('completed', {
      loggedIn,
      email,
      customerAccessToken,
      customerAccessTokenExpiresAt,
      shopPayInstallmentsOnboarded,
    });

    this._maybeRedirect();
  }

  async _handleCustomFlowSideEffect(payload: CustomFlowSideEffectEvent) {
    // currently only used for Prequal flow, but can be extended to other flows
    switch (payload.flow) {
      case ShopActionType.Prequal:
        this.dispatchCustomEvent('prequal_flow_side_effect', {
          shopPayInstallmentsOnboarded: payload.shopPayInstallmentsOnboarded,
        });
        break;
    }
  }

  _maybeRedirect(): void {
    if (this.#redirectType === 'pop_up') return;
    if (!this.#redirectUri) return;

    try {
      let redirectUri = this.#redirectUri;
      if (this.#analyticsTraceId) {
        const queryParams = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          analytics_trace_id: this.#analyticsTraceId,
        };
        const searchParams = new URLSearchParams(queryParams);
        redirectUri = redirectUri.concat(`?${searchParams.toString()}`);
      } else {
        Bugsnag.notify(
          new Error(
            'Missing analytics trace ID when redirecting to account page',
          ),
        );
      }

      window.location.assign(redirectUri);
    } catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
    }
  }

  _handleError(message: string, code: SdkErrorCode): void {
    this.dispatchCustomEvent('error', {
      message,
      code,
    });

    if (SILENT_SDK_ERROR_CODES.includes(code)) {
      recordOpentel(TelemetryMetricId.HandleSilentError, 1, {
        component: 'shop-login-default',
        errorCode: code,
      });
    } else {
      Bugsnag.notify(new Error(`Authorize Error: ${message} (${code}).`));
    }

    this.#clearLoadTimeout();
  }

  _onPopUpOpened(payload: {
    didOpen?: boolean;
    popUpName?: string;
    popUpFeatures?: string;
  }): void {
    if (payload.didOpen) {
      this._view?.dispatch({
        type: AuthorizeModalTextActionType.PopUpOpened,
        payload,
      });
    }

    this.dispatchCustomEvent('popuploading', payload);
  }

  _onLoaded(payload: AuthorizeLoadedEvent) {
    this._view?.setAuthenticationLevel(
      payload?.authenticationLevelRequired || AuthenticationLevel.Phone,
    );
    this._view?.dispatch({
      type: AuthorizeModalTextActionType.Init,
      payload,
    });
    this._view?.onContentLoaded();
  }

  _onUserMatched({
    hasName = false,
    userCookieExists = false,
    personalizeConsentChallenge = false,
  }: ShopUserMatchedEvent): void {
    this._view?.dispatch({
      type: AuthorizeModalTextActionType.UserMatched,
      payload: {
        hasName,
        userCookieExists,
        personalizeConsentChallenge,
      },
    });
    unzoomIos('shop-login-default-request-focus-input');
    this.dispatchCustomEvent('shopusermatched');
  }

  _onUserNotMatched({apiError}: ShopUserNotMatchedEvent): void {
    this._view?.dispatch({
      type: AuthorizeModalTextActionType.UserNotMatched,
    });
    this.dispatchCustomEvent('shopusernotmatched', {apiError});
  }

  _onEmailChangeRequested(): void {
    this._view?.dispatch({
      type: AuthorizeModalTextActionType.Restart,
    });
    this.dispatchCustomEvent('restarted');
  }

  _onVerificationStepChanged(payload: VerificationStepChangedEvent): void {
    this._view?.dispatch({
      type: AuthorizeModalTextActionType.VerificationStepChanged,
      payload,
    });
  }

  #handlePostMessage(data: MessageEventData) {
    switch (data.type) {
      case 'loaded':
        this._onLoaded(data);
        break;
      case 'resize_iframe':
        if (!this._shouldListenToResizeMessage) {
          return;
        }

        this._view?.resizeIframe(
          data.height,
          constraintWidthInViewport(data.width, this._view!.getIframe()!),
        );
        break;
      case 'completed':
        this._handleCompleted(
          data.loggedIn,
          data.email,
          data.customerAccessToken,
          data.customerAccessTokenExpiresAt,
          data.shouldFinalizeLogin,
          data.shopPayInstallmentsOnboarded,
          data.givenNameFirstInitial,
          data.avatar,
        );
        break;
      case 'error':
        this._handleError(data.message, data.code);
        break;
      case 'close_requested':
        this._view?.closeAuthorizeModal();
        break;
      case 'shop_user_matched':
        this._onUserMatched(data);
        break;
      case 'pop_up_opened':
        this._onPopUpOpened(data);
        break;
      case 'shop_user_not_matched':
        this._onUserNotMatched(data);
        break;
      case 'email_change_requested':
        this._onEmailChangeRequested();
        break;
      case 'verification_step_changed':
        this._onVerificationStepChanged(data);
        break;
      case 'custom_flow_side_effect':
        this._handleCustomFlowSideEffect(data);
        break;
    }
  }
}

/**
 * Define the shop-login-default custom element.
 */
export function defineElement() {
  defineCustomElement('shop-login-default', ShopLoginDefault);
}
