import {defineCustomElement} from '../../common/init';
import {getValidAttribute} from '../../common/utils/attributeValidator';
import {PositionVariant, getAnalyticsTraceId} from '../../common/utils';
import WebComponent from '../../common/WebComponent';
import {
  ShopLoginEventType,
  ShopActionType,
  LoginButtonVersion as Version,
  LoginButtonUxMode as UxMode,
} from '../../types';
import {
  ATTRIBUTE_ACTION,
  ATTRIBUTE_ANALYTICS_CONTEXT,
  ATTRIBUTE_ANALYTICS_TRACE_ID,
  ATTRIBUTE_AUTO_OPEN,
  ATTRIBUTE_CLIENT_ID,
  ATTRIBUTE_DISABLE_SIGN_UP,
  ATTRIBUTE_EMAIL_VERIFICATION_REQUIRED,
  ATTRIBUTE_HIDE_BUTTON,
  ATTRIBUTE_REDIRECT_TYPE,
  ATTRIBUTE_REDIRECT_URI,
  ATTRIBUTE_UX_MODE,
  ATTRIBUTE_RESPONSE_TYPE,
  ATTRIBUTE_RESPONSE_MODE,
  ATTRIBUTE_CODE_CHALLENGE,
  ATTRIBUTE_CODE_CHALLENGE_METHOD,
  ATTRIBUTE_STATE,
  ATTRIBUTE_SCOPE,
  ATTRIBUTE_STOREFRONT_ORIGIN,
  ATTRIBUTE_VERSION,
  ATTRIBUTE_AVOID_PAY_ALT_DOMAIN,
  ATTRIBUTE_FLOW,
  ATTRIBUTE_FLOW_VERSION,
  ATTRIBUTE_ANCHOR_SELECTOR,
  ATTRIBUTE_KEEP_MODAL_OPEN,
  ATTRIBUTE_EMAIL,
  ATTRIBUTE_DEV_MODE,
  ATTRIBUTE_MODAL_TITLE,
  ATTRIBUTE_MODAL_DESCRIPTION,
  ATTRIBUTE_MODAL_LOGO_SRC,
  ATTRIBUTE_API_KEY,
  ATTRIBUTE_POP_UP_NAME,
  ATTRIBUTE_POP_UP_FEATURES,
  ATTRIBUTE_MODAL_BRAND,
  ATTRIBUTE_CONSENT_CHALLENGE,
  ATTRIBUTE_CHECKOUT_REDIRECT_URL,
  ATTRIBUTE_CHECKOUT_VERSION,
  ATTRIBUTE_SHOP_ID,
  ATTRIBUTE_SHOP_PERMANENT_DOMAIN,
  ATTRIBUTE_FIRST_NAME,
  ATTRIBUTE_LAST_NAME,
  ATTRIBUTE_REQUIRE_VERIFICATION,
  ATTRIBUTE_CHECKOUT_TOKEN,
  ATTRIBUTE_TRANSACTION_PARAMS,
  ATTRIBUTE_COMPACT,
  ATTRIBUTE_POSITION_VARIANT,
  ATTRIBUTE_AVOID_SDK_SESSION,
  ATTRIBUTE_SOURCE,
} from '../../constants/loginButton';

import ShopFollowButton from './shop-follow-button';
import ShopLoginDefault from './shop-login-default';

const actionTypeToTagName: {[key in ShopActionType]: string | null} = {
  [ShopActionType.Follow]: 'shop-follow-button',
  [ShopActionType.Default]: 'shop-login-default',
  // Prequal uses the same component as Default
  [ShopActionType.Prequal]: 'shop-login-default',
  [ShopActionType.PopUp]: 'shop-login-default',
  // Checkout sheet flow (action) is not supported by `shop-login-button`
  // It can only be used by rendering `shop-pay-checkout-sheet` directly
  [ShopActionType.CheckoutSheet]: null,
  [ShopActionType.Custom]: 'shop-login-default',
};

interface ShopLoginButtonAttributes {
  [ATTRIBUTE_VERSION]?: Version;
  [ATTRIBUTE_CLIENT_ID]?: string;
  [ATTRIBUTE_EMAIL_VERIFICATION_REQUIRED]: boolean;
  [ATTRIBUTE_HIDE_BUTTON]: boolean;
  [ATTRIBUTE_DISABLE_SIGN_UP]: boolean;
  [ATTRIBUTE_AUTO_OPEN]: boolean;
  [ATTRIBUTE_REDIRECT_TYPE]?: string | null;
  [ATTRIBUTE_REDIRECT_URI]?: string | null;
  [ATTRIBUTE_UX_MODE]?: UxMode | null;
  [ATTRIBUTE_ANALYTICS_CONTEXT]: string;
  [ATTRIBUTE_ANALYTICS_TRACE_ID]?: string;
  [ATTRIBUTE_COMPACT]?: boolean;
  [ATTRIBUTE_POSITION_VARIANT]?: PositionVariant;
  [ATTRIBUTE_RESPONSE_TYPE]?: string | null;
  [ATTRIBUTE_RESPONSE_MODE]?: string | null;
  [ATTRIBUTE_CODE_CHALLENGE]?: string | null;
  [ATTRIBUTE_CODE_CHALLENGE_METHOD]?: string | null;
  [ATTRIBUTE_STATE]?: string | null;
  [ATTRIBUTE_SCOPE]?: string | null;
  [ATTRIBUTE_AVOID_PAY_ALT_DOMAIN]: boolean;
  [ATTRIBUTE_AVOID_SDK_SESSION]: boolean;
  [ATTRIBUTE_ACTION]?: ShopActionType;
  [ATTRIBUTE_FLOW]?: ShopActionType;
  [ATTRIBUTE_FLOW_VERSION]: string;
  [ATTRIBUTE_EMAIL]: string;
  [ATTRIBUTE_ANCHOR_SELECTOR]?: string | null;
  [ATTRIBUTE_API_KEY]?: string | null;
  [ATTRIBUTE_DEV_MODE]: boolean;
  [ATTRIBUTE_KEEP_MODAL_OPEN]: boolean;
  [ATTRIBUTE_MODAL_TITLE]?: string | null;
  [ATTRIBUTE_MODAL_DESCRIPTION]?: string | null;
  [ATTRIBUTE_MODAL_LOGO_SRC]?: string | null;
  [ATTRIBUTE_POP_UP_NAME]?: string | null;
  [ATTRIBUTE_POP_UP_FEATURES]?: string | null;
  [ATTRIBUTE_MODAL_BRAND]?: string | null;
  [ATTRIBUTE_CONSENT_CHALLENGE]: boolean;
  [ATTRIBUTE_CHECKOUT_REDIRECT_URL]?: string | null;
  [ATTRIBUTE_CHECKOUT_VERSION]?: string | null;
  [ATTRIBUTE_CHECKOUT_TOKEN]?: string | null;
  [ATTRIBUTE_TRANSACTION_PARAMS]?: string | null;
  [ATTRIBUTE_SHOP_ID]?: string;
  [ATTRIBUTE_SHOP_PERMANENT_DOMAIN]?: string;
  [ATTRIBUTE_FIRST_NAME]?: string;
  [ATTRIBUTE_LAST_NAME]?: string;
  [ATTRIBUTE_REQUIRE_VERIFICATION]: boolean;
  [ATTRIBUTE_SOURCE]: string;
}

const defaultAttributes: ShopLoginButtonAttributes = {
  [ATTRIBUTE_EMAIL_VERIFICATION_REQUIRED]: false,
  [ATTRIBUTE_HIDE_BUTTON]: false,
  [ATTRIBUTE_DISABLE_SIGN_UP]: false,
  [ATTRIBUTE_AUTO_OPEN]: false,
  [ATTRIBUTE_ANALYTICS_CONTEXT]: '',
  [ATTRIBUTE_AVOID_PAY_ALT_DOMAIN]: false,
  [ATTRIBUTE_AVOID_SDK_SESSION]: false,
  [ATTRIBUTE_FLOW_VERSION]: 'unspecified',
  [ATTRIBUTE_EMAIL]: '',
  [ATTRIBUTE_DEV_MODE]: false,
  [ATTRIBUTE_KEEP_MODAL_OPEN]: false,
  [ATTRIBUTE_CONSENT_CHALLENGE]: false,
  [ATTRIBUTE_REQUIRE_VERIFICATION]: false,
  [ATTRIBUTE_SOURCE]: 'unspcified',
};

export default class ShopLoginButton extends WebComponent {
  #actionType: ShopActionType | undefined;
  #actionButton: ShopFollowButton | ShopLoginDefault | undefined;

  static get observedAttributes(): string[] {
    return [
      ATTRIBUTE_CLIENT_ID,
      ATTRIBUTE_VERSION,
      ATTRIBUTE_FLOW_VERSION,
      ATTRIBUTE_STOREFRONT_ORIGIN,
      ATTRIBUTE_EMAIL_VERIFICATION_REQUIRED,
      ATTRIBUTE_HIDE_BUTTON,
      ATTRIBUTE_DISABLE_SIGN_UP,
      ATTRIBUTE_AUTO_OPEN,
      ATTRIBUTE_COMPACT,
      ATTRIBUTE_POSITION_VARIANT,
      ATTRIBUTE_REDIRECT_TYPE,
      ATTRIBUTE_REDIRECT_URI,
      ATTRIBUTE_ANALYTICS_CONTEXT,
      ATTRIBUTE_REDIRECT_URI,
      ATTRIBUTE_UX_MODE,
      ATTRIBUTE_RESPONSE_TYPE,
      ATTRIBUTE_RESPONSE_MODE,
      ATTRIBUTE_CODE_CHALLENGE,
      ATTRIBUTE_CODE_CHALLENGE_METHOD,
      ATTRIBUTE_STATE,
      ATTRIBUTE_SCOPE,
      ATTRIBUTE_EMAIL,
      ATTRIBUTE_ANCHOR_SELECTOR,
      ATTRIBUTE_DEV_MODE,
      ATTRIBUTE_MODAL_TITLE,
      ATTRIBUTE_MODAL_DESCRIPTION,
      ATTRIBUTE_MODAL_LOGO_SRC,
      ATTRIBUTE_API_KEY,
      ATTRIBUTE_POP_UP_NAME,
      ATTRIBUTE_POP_UP_FEATURES,
      ATTRIBUTE_MODAL_BRAND,
      ATTRIBUTE_CONSENT_CHALLENGE,
      ATTRIBUTE_ANALYTICS_TRACE_ID,
      ATTRIBUTE_CHECKOUT_REDIRECT_URL,
      ATTRIBUTE_CHECKOUT_VERSION,
      ATTRIBUTE_CHECKOUT_TOKEN,
      ATTRIBUTE_TRANSACTION_PARAMS,
      ATTRIBUTE_SHOP_ID,
      ATTRIBUTE_SHOP_PERMANENT_DOMAIN,
      ATTRIBUTE_FIRST_NAME,
      ATTRIBUTE_LAST_NAME,
      ATTRIBUTE_REQUIRE_VERIFICATION,
      ATTRIBUTE_AVOID_PAY_ALT_DOMAIN,
      ATTRIBUTE_AVOID_SDK_SESSION,
      ATTRIBUTE_KEEP_MODAL_OPEN,
      ATTRIBUTE_SOURCE,
    ];
  }

  // Allows getting the clientId as a property externally. Internally, use the private _clientId variable.
  get clientId(): string | undefined {
    return this._getAttributeValueWithDefault(ATTRIBUTE_CLIENT_ID);
  }

  // Allows setting the clientId property externally, reflects value in the attribute.
  set clientId(newClientId: string | undefined) {
    this.updateAttribute(ATTRIBUTE_CLIENT_ID, newClientId);
  }

  set redirectUri(newRedirectUri: string | undefined) {
    this.updateAttribute(ATTRIBUTE_REDIRECT_URI, newRedirectUri);
  }

  get version(): Version | undefined {
    return this._getAttributeValueWithDefault(ATTRIBUTE_VERSION);
  }

  set version(newVersion: Version | undefined) {
    this.updateAttribute(ATTRIBUTE_VERSION, newVersion);
  }

  get email(): string {
    return this._getAttributeValueWithDefault(ATTRIBUTE_EMAIL);
  }

  set email(newEmail: string) {
    this.updateAttribute(ATTRIBUTE_EMAIL, newEmail);
  }

  set firstName(newFirstName: string | undefined) {
    this.updateAttribute(ATTRIBUTE_FIRST_NAME, newFirstName);
  }

  set lastName(newLastName: string | undefined) {
    this.updateAttribute(ATTRIBUTE_LAST_NAME, newLastName);
  }

  set popUpName(newPopUpName: string | undefined) {
    this.updateAttribute(ATTRIBUTE_POP_UP_NAME, newPopUpName);
  }

  set popUpFeatures(newPopUpFeatures: string | undefined) {
    this.updateAttribute(ATTRIBUTE_POP_UP_FEATURES, newPopUpFeatures);
  }

  connectedCallback(): void {
    this.#actionType = getValidAttribute<ShopActionType>(
      this.getAttribute(ATTRIBUTE_ACTION),
      ShopActionType,
      ShopActionType.Default,
    );

    this.#actionButton = this._createActionButton({
      actionType: this.#actionType,
      attributes: this._getAttributeValues(),
    });
    if (!this.shadowRoot) {
      this.attachShadow({mode: 'open'});
    }
    if (this.#actionButton) {
      this.shadowRoot!.innerHTML = '';
      this.shadowRoot?.appendChild(this.#actionButton);
    }
  }

  disconnectedCallback(): void {}

  attributeChangedCallback(
    name: keyof ShopLoginButtonAttributes,
    _oldValue: string,
    newValue: string | null,
  ): void {
    const attributeValue = newValue || this._getAttributeValueWithDefault(name);

    if (typeof attributeValue === 'boolean') {
      this.updateAttribute(name, attributeValue ? '' : undefined);
    } else {
      this.updateAttribute(name, attributeValue || undefined);
    }

    if (newValue === null) {
      this.#actionButton?.removeAttribute(name);
    } else {
      this.#actionButton?.setAttribute(name, newValue);
    }
  }

  requestShow(email: string): void {
    if (this.#actionButton && 'requestShow' in this.#actionButton) {
      this.#actionButton.requestShow(email);
    }
  }

  listenToInput(inputElement: HTMLInputElement): void {
    if (this.#actionButton && 'listenToInput' in this.#actionButton) {
      this.#actionButton.listenToInput(inputElement);
    }
  }

  stopListeningToInput() {
    if (!(this.#actionButton && 'stopListeningToInput' in this.#actionButton))
      return;

    this.#actionButton.stopListeningToInput();
  }

  // Overrides to enforce type safety
  protected dispatchCustomEvent(type: ShopLoginEventType, detail?: any): void {
    super.dispatchCustomEvent(type, detail);
  }

  private _createActionButton({
    actionType,
    attributes,
  }: {
    actionType: ShopActionType;
    attributes: ShopLoginButtonAttributes;
  }): ShopFollowButton | ShopLoginDefault | undefined {
    const tagName = actionTypeToTagName[actionType];
    if (!tagName) return undefined;

    const actionButton = document.createElement(tagName) as
      | ShopFollowButton
      | ShopLoginDefault
      | undefined;
    if (!actionButton) return undefined;

    Object.entries(attributes).forEach(([key, value]) => {
      if (value) {
        actionButton.setAttribute(key, String(value));
      }
    });

    return actionButton;
  }

  private _getAttributeValues() {
    return ShopLoginButton.observedAttributes.reduce(
      (acc, attributeName) => ({
        ...acc,
        [attributeName]: this._getAttributeValueWithDefault(
          attributeName as keyof ShopLoginButtonAttributes,
        ),
      }),
      {
        ...defaultAttributes,
        [ATTRIBUTE_FLOW]: this._getAttributeValueWithDefault(
          ATTRIBUTE_ACTION,
        ) as ShopActionType,
      },
    );
  }

  private _getAttributeValueWithDefault<
    TAttributeName extends keyof ShopLoginButtonAttributes,
  >(attributeName: TAttributeName): ShopLoginButtonAttributes[TAttributeName] {
    switch (attributeName) {
      case ATTRIBUTE_ACTION:
      case ATTRIBUTE_FLOW:
        return getValidAttribute<ShopActionType>(
          this.getAttribute(ATTRIBUTE_ACTION),
          ShopActionType,
          ShopActionType.Default,
        ) as ShopLoginButtonAttributes[TAttributeName];
      case ATTRIBUTE_EMAIL_VERIFICATION_REQUIRED:
      case ATTRIBUTE_HIDE_BUTTON:
      case ATTRIBUTE_DISABLE_SIGN_UP:
      case ATTRIBUTE_AUTO_OPEN:
      case ATTRIBUTE_AVOID_PAY_ALT_DOMAIN:
      case ATTRIBUTE_AVOID_SDK_SESSION:
      case ATTRIBUTE_DEV_MODE:
      case ATTRIBUTE_KEEP_MODAL_OPEN:
      case ATTRIBUTE_CONSENT_CHALLENGE:
      case ATTRIBUTE_REQUIRE_VERIFICATION:
      case ATTRIBUTE_COMPACT:
        return this.getBooleanAttribute(
          attributeName,
        ) as ShopLoginButtonAttributes[TAttributeName];
      case ATTRIBUTE_ANALYTICS_CONTEXT:
      case ATTRIBUTE_EMAIL:
        return (this.getAttribute(attributeName) ||
          '') as ShopLoginButtonAttributes[TAttributeName];
      case ATTRIBUTE_FLOW_VERSION:
        return (this.getAttribute(attributeName) ||
          'unspecified') as ShopLoginButtonAttributes[TAttributeName];
      case ATTRIBUTE_ANALYTICS_TRACE_ID:
        return (this.getAttribute(attributeName) ||
          getAnalyticsTraceId()) as ShopLoginButtonAttributes[TAttributeName];
      default:
        return (this.getAttribute(attributeName) ||
          undefined) as ShopLoginButtonAttributes[TAttributeName];
    }
  }
}

/**
 * Define the shop-login-button custom element.
 */
export function defineElement() {
  defineCustomElement('shop-login-button', ShopLoginButton);
}
